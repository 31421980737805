import { defineMessages } from 'react-intl';

export const settings = 'settings';
export const settingsMessages = defineMessages({
  pageTitle: {
    id: `settings:pageTitle`,
    defaultMessage: 'Mínar upplýsingar',
    description: 'Titill síðu',
  },

  heroTitle: {
    id: `settings:hero-title`,
    defaultMessage: 'Mínar upplýsingar',
    description: 'Titill síðu/titill í meginmáli',
  },
  heroText: {
    id: `settings:hero-text`,
    defaultMessage: 'Hér getur þú breytt upplýsingum um netfang ásamt aðgangsstýringum.',
    description: 'Megin texti síðu',
  },
  heroLink: {
    id: `settings:hero-link`,
    defaultMessage: '',
    description: 'Slóð á hlekk í meginmáli',
  },
  heroLinkText: {
    id: `settings:hero-link-text`,
    defaultMessage: '',
    description: 'Texti á hlekk í meginmáli',
  },
  heroFootNote: {
    id: `settings:hero-footnote`,
    defaultMessage: '',
    description: 'Undirmálsgreinar í meginmáli',
  },

  generalSettings: {
    id: `settings:general-settings`,
    defaultMessage: 'Almennar stillingar',
  },
  generalSettingsFieldEmail: {
    id: `settings:general-settings-field-email`,
    defaultMessage: 'Tölvupóstur',
  },
  generalSettingsFieldCheckbox: {
    id: `settings:general-settings-field-checkbox`,
    defaultMessage: 'Afþakka öll pappírsgögn',
  },
  generalSettingsFieldTooltip: {
    id: `settings:general-settings-field-tooltip`,
    description: 'Upplýsingatexti fyrir afþakka pappírsgögn',
  },
  generalSettingsFieldPhone: {
    id: `settings:general-settings-field-phone`,
    defaultMessage: 'Sími',
  },
  generalSettingsButtonText: {
    id: `settings:general-settings-button-text`,
    defaultMessage: 'Vista breytingar',
  },
  generalSettingsButtonSaved: {
    id: `settings:general-settings-button-saved`,
    defaultMessage: 'Vistað',
    description: 'Staðfesting á vistun á breytingum í mínum upplýsingum',
  },

  accessControls: {
    id: `settings:access-controls`,
    defaultMessage: 'Aðgangsstýringar',
  },
  accessControlsNoRegnoRegisteredText: {
    id: `settings:access-controls-no-regno-registered-text`,
    defaultMessage: 'Engin kennitala er skráð',
  },
  accessControlsFieldDefaultFundMember: {
    id: `settings:access-controls-field-default-fund-member`,
    defaultMessage: 'Sjálfgefinn sjóðfélagi',
  },
  accessControlsFieldRegno: {
    id: `settings:access-controls-field-regno`,
    defaultMessage: 'Kennitala',
  },

  accessControlsRegnosICanAccess: {
    id: `settings:access-controls-regnos-i-can-access`,
    defaultMessage: 'Aðilar sem ég hef aðgang að',
  },
  accessControlsGiveAccess: {
    id: `settings:access-controls-give-access`,
    defaultMessage: 'Veita aðgang',
  },

  accessControlsRegnosWithAccessToMe: {
    id: `settings:access-controls-regnos-with-access-to-me`,
    defaultMessage:
      'Hægt er að veita tengdum aðilu aðgang að sjóðfélagavef. Hér birtast aðilar sem hafa aðgang fyrir þína hönd',
  },
  accessControlsRegnosWithAccessToMeNoRegnoRegisteredText: {
    id: `settings:access-controls-regnos-with-access-to-me-no-regno-registered-text`,
    defaultMessage: 'Engin aðili er skráður',
  },
  accessControlsRegnosWithAccessToMeFieldRegno: {
    id: `settings:access-controls-regnos-with-access-to-me-field-regno`,
    defaultMessage: 'Kennitala',
  },
  accessControlsRegnosWithAccessToMeFieldAddRegnoLabel: {
    id: `settings:access-controls-regnos-with-access-to-me-field-add-regno-label`,
    defaultMessage: 'Skrá aðila',
  },
  accessControlsRegnosWithAccessToMeFieldAddRegnoPlaceholder: {
    id: `settings:access-controls-regnos-with-access-to-me-field-add-regno-placeholder`,
    defaultMessage: 'Kennitala',
  },
  accessControlsRegnosWithAccessToMeAddRegnoButtonText: {
    id: `settings:access-controls-regnos-with-access-to-me-add-regno-button-text`,
    defaultMessage: 'Bæta kennitölu við listann +',
  },
  accessControlsRegnosWithAccessToMeSubmitButtonText: {
    id: `settings:access-controls-regnos-with-access-to-me-submit-button-text`,
    defaultMessage: 'Vista breytingar',
  },
  accessControlsRegnosGiveAccessToMeSubmitButtonText: {
    id: `settings:access-controls-regnos-give-access-to-me-submit-button-text`,
    defaultMessage: 'Veita aðgang',
  },

  infoTitle: { id: 'settings:info-title', defaultMessage: 'Réttar upplýsingar' },
  infoEyebrow: { id: 'settings:info-eyebrow', defaultMessage: 'Fræðsla' },
  infoText: {
    id: 'settings:info-text',
    defaultMessage:
      'Gott er að gæta þess að upplýsingar skráðar hér séu ávalt réttar svo tilkynningar berist með skjótum hætti.',
  },
  errorSiteTitle: {
    id: `settings:error-site-title`,
    defaultMessage: '404 - Síða fannst ekki',
  },
  errorSiteDescription: {
    id: `settings:error-site-description`,
    defaultMessage:
      'Við biðjumst velvirðingar en engin síða finnst á þessari slóð. Ástæðan gæti verið ein af eftirfarandi:',
  },
  errorSitePageNotExist: {
    id: `settings:error-site-page-not-exist`,
    defaultMessage:
      'Slóðin á síðuna er ekki til, villa var gerð við innslátt eða tengillinn sem vísaði á síðuna er rangur',
  },
  errorSitePageRemoved: {
    id: `settings:error-site-page-removed`,
    defaultMessage: 'Síðan hefur verið fjarlægð og er því ekki lengur til á vefþjóni',
  },
  errorSitePageNotAvailable: {
    id: `settings:error-site-page-not-available`,
    defaultMessage: 'Síðan er ekki aðgengileg þessa stundina',
  },
  errorSiteButtonText: {
    id: `settings:error-site-button-text`,
    defaultMessage: 'Fara á forsíðu',
  },
  isErrorSiteTitle: {
    id: `settings:is-error-site-title`,
    defaultMessage: 'Villa hefur komið upp',
  },
  isErrorSiteDescription: {
    id: `settings:is-error-site-description`,
    defaultMessage:
      'Villa hefur komið við innskráningu á island.is, vinsamlegast reynið aftur síðar.',
  },
  grantUserAccessError: {
    id: `settings:grant-user-access-error`,
    defaultMessage:
      'Aðili ekki skráður, viðkomandi þarf að skrá sig inn allavega einu sinni til að geta fengið aðgang.',
  },
});
