/* wrapper component to pass to some non-reset styles for html elements */
import classnames from 'classnames/bind';

import s from './RichText.module.scss';

const c = classnames.bind(s);

type IProps = {
  html?: string;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export const RichTextStatic = ({ html, children, className, style }: IProps) => {
  if (!html && !children) {
    return null;
  }

  const passProps: IProps = {};
  passProps.className = c(s.richText, className);
  passProps.style = style;

  // prioritise children
  if (children) {
    if (typeof children === 'string') {
      const alteredChildren = children.split(/\\n\n/g);

      return (
        <div {...passProps}>
          {alteredChildren.map((child, i) => (
            <p
              key={`rich-text-${i}`}
              dangerouslySetInnerHTML={{ __html: child.replace(/\\n/g, '<br />') }}
            />
          ))}
        </div>
      );
    }

    return <div {...passProps}>{children}</div>;
  }

  return <div {...passProps} dangerouslySetInnerHTML={{ __html: html ?? '' }} />;
};
