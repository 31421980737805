import { Error404 } from '@hxm/components/error/Error404';
import { Section } from '@hxm/components/section/Section';

export default function Custom404() {
  return (
    <Section container>
      <Error404 />
    </Section>
  );
}
